import React from "react";
import safaris from "../../components/Safaris";
import Header from "../../components/Header";
import HeaderImage from "../../images/Stag.JPG";
import { Link } from "react-router-dom";
import Card from "../../UI/Card";
import { plans } from "../../data";
import "./Safaris.css";

const Safaris = () => {
  return (
    <>
      <Header title="Our Safari Options" image={HeaderImage}>
        From birds to butterflies, and Orcas to Otters, our wildlife safaris
        offer a range of opportunities to see different species both on land and
        at sea, depending on where you are and what time of year it is. We also
        offer a range of adventure experiences, all to be enjoyed amongst
        Scotland’s breath-taking scenery. Many, if not most of our safaris
        combine both wildlife and adventure, but we can create bespoke
        experiences to suit each individual preference.
      </Header>
      <section className="safaris">
        <div className="container plans__container">
          {plans.map(({ id, name, desc, price, features }) => {
            return (
              <Card key={id} className="plan">
                <h3>{name}</h3>
                <small>{desc}</small>
                <h1>{`£${price}`}</h1>
                <h4>Features</h4>
                {features.map(({ feature, available }, index) => {
                  return (
                    <p key={index} className={!available ? "disabled" : ""}>
                      {feature}
                    </p>
                  );
                })}
                <Link to="/trip">
                  <button className="btn lg">Plan Your Trip</button>
                </Link>
              </Card>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Safaris;
