import { GiPineTree } from "react-icons/gi";
import { SiOpenaigym } from "react-icons/si";
import { React } from "react";

export const links = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About",
    path: "/about",
  },
  {
    name: "Safaris",
    path: "/safaris",
  },
  {
    name: "Plan Your Trip",
    path: "/trip",
  },
  {
    name: "Gallery",
    path: "/gallery",
  },
  {
    name: "Contact",
    path: "/contact",
  },
];

export const safaris = [
  {
    id: 1,
    icon: <GiPineTree />,
    title: "Adventure Safaris",
    info: "The caribbean seas, an abundance of wildlife and breathtaking views. ",
    path: "/safaris/111",
  },
  {
    id: 2,
    icon: <GiPineTree />,
    title: "Winter Safaris",
    info: "Being out in the elements, feeling the power of mother nature you will be sure to experience over the winter. It truly makes you feel alive!",
    path: "/safaris/222",
  },
  {
    id: 3,
    icon: <GiPineTree />,
    title: "Wildlife Safaris",
    info: "Experince places very few have been to before, and even better, with no one else around you!",
    path: "/safaris/333",
  },
  {
    id: 4,
    icon: <GiPineTree />,
    title: "Bespoke Safaris",
    info: "Dive deeper into what Scotland has to offer. We can dynamically draw up a bespoke package tailored to your preferences!",
    path: "/safaris/444",
  },
];

export const values = [
  {
    id: 1,
    icon: <SiOpenaigym />,
    title: "Value One",
    desc: "Placeat quidem facere dicta modi? Pariatur exercitationem illum.",
  },
  {
    id: 2,
    icon: <SiOpenaigym />,
    title: "Value Two",
    desc: "Placeat quidem facere dicta modi? Pariatur exercitationem illum.",
  },
  {
    id: 3,
    icon: <SiOpenaigym />,
    title: "Value Three",
    desc: "Placeat quidem facere dicta modi? Pariatur exercitationem illum.",
  },
  {
    id: 4,
    icon: <SiOpenaigym />,
    title: "Value Four",
    desc: "Placeat quidem facere dicta modi? Pariatur exercitationem illum.",
  },
];

export const faqs = [
  {
    id: 1,
    question: "What types of tours do you offer?",
    answer:
      "We offer a range of tours, including cultural tours, adventure trips, historical sightseeing, wildlife safaris, and customized private tours. Whether you're looking for group experiences or solo journeys, we have options to suit every traveller.",
  },
  {
    id: 2,
    question: "Can I customize my tour?",
    answer:
      "Yes, we offer customizable tours to match your preferences, including changes to the itinerary, accommodations, activities, and more. Our team will work with you to create the perfect trip.",
  },
  {
    id: 3,
    question: "Do I need travel insurance?",
    answer:
      "Yes, we strongly recommend purchasing travel insurance to cover unforeseen events such as trip cancellations, medical emergencies, or lost luggage. We can help you find the right insurance plan.",
  },
  {
    id: 4,
    question: "What is the best time to book a tour?",
    answer:
      "It depends on the destination and season. For popular destinations, we recommend booking at least 6-12 months in advance to secure your preferred dates and pricing. Off-season trips may offer more flexibility.",
  },
  {
    id: 5,
    question: "What’s your cancellation and refund policy?",
    answer:
      "Our cancellation policy varies based on the tour and operator. Full refunds may be available if you cancel within a specified timeframe. We provide all details when you book, and we try to offer flexible options when possible.",
  },
  {
    id: 6,
    question: "Do you offer transportation to and from the airport?",
    answer:
      "Yes, airport transfers are often included in our packages or can be arranged upon request for an additional fee. Check your specific tour for details.",
  },
  {
    id: 7,
    question: "Are flights included in the tour package?",
    answer:
      "Flights are usually not included in our standard packages, but we can help you book them separately or offer recommendations. Some international tours may include domestic flights within the destination.",
  },
  {
    id: 8,
    question: "Do I need a visa for the tour?",
    answer:
      "Visa requirements depend on your destination and nationality. We can provide guidance and help you with the necessary documentation for your trip.",
  },
  {
    id: 9,
    question: "What type of accommodations can I expect?",
    answer:
      "Our tours typically include comfortable, clean accommodations ranging from 3 to 5 stars, depending on your package. We also offer eco-lodges, boutique hotels, and homestays for more unique experiences.",
  },
  {
    id: 10,
    question: "How physically demanding are your tours?",
    answer:
      "We offer tours for all fitness levels, from leisurely sightseeing to more strenuous adventures like hiking or trekking. Each tour description will indicate the level of physical activity involved.",
  },
  {
    id: 11,
    question: "Do you provide local guides?",
    answer:
      "Yes, we work with experienced local guides who are experts in their region, ensuring an authentic and informative experience.",
  },
];

export const testimonials = [
  {
    id: 1,
    name: "Max D.",
    quote:
      "Our Scotland wildlife trip was truly a magical experience! From exploring the rugged Highlands to spotting majestic red deer and golden eagles in their natural habitats, every moment was unforgettable. The tour guides were incredibly knowledgeable and passionate, making the entire journey both educational and exciting. One of the highlights was a boat trip to see seals and puffins up close, which was a dream come true! The landscapes were breathtaking, and the wildlife encounters were fantastic. We left with a deep appreciation for Scotland's natural beauty and can't wait to return!",
    job: "Marketing Manager",
    avatar: require("./images/Avatars/avatar2.jpg"),
  },
  {
    id: 2,
    name: "Kavita S.",
    quote:
      "Our adventure in Scotland was beyond anything we could have imagined! From hiking the dramatic peaks of the Isle of Skye to kayaking along the stunning coastline, every day was a new thrill. The landscapes were wild and untamed, offering the perfect backdrop for an adrenaline-packed journey. We tackled the famous Ben Nevis climb, explored hidden waterfalls, and even tried canyoning! The guides were fantastic, ensuring safety while pushing us to explore our limits. Scotland’s natural beauty combined with heart-racing activities made this an adventure of a lifetime. We’re already planning our next trip!",
    job: "Software Engineer",
    avatar: require("./images/Avatars/avatar1.jpg"),
  },
  // {
  //   id: 3,
  //   name: "Edem Quist",
  //   quote:
  //     "Quaerat hic praesentium consequuntur molestias repellat ad quo tenetur vitae rem, labore quisquam? Atque, assumenda rerum odit harum quaerat hic praesentium quisquam quae, enim iste ipsam id repellat.",
  //   job: "University Lecturer",
  //   avatar: require("./images/Avatars/avatar3.jpg"),
  // },
  // {
  //   id: 4,
  //   name: "Grace Lavoe",
  //   quote:
  //     "Cupiditate deleniti sint consequuntur molestias repellat ad quo tenetur vitae rem, labore quisquam? Atque, assumenda rerum odit harum quaerat hic praesentium quisquam quae, enim iste ipsam id repellat.",
  //   job: "Talking Parrot",
  //   avatar: require("./images/Avatars/avatar4.jpg"),
  // },
  // {
  //   id: 5,
  //   name: "Nana Yaa Dankwa",
  //   quote:
  //     "Maxime minima cumque sit amet consectetur adipisicing elit. Praesentium ipsam facere ea a laboriosam sed? Quod vel voluptates a! Maxime minima cumque aut? In expedita numquam consectetur non officia iusto.",
  //   job: "Pharmacist",
  //   avatar: require("./images/Avatars/avatar5.jpg"),
  // },
];

export const plans = [
  {
    id: 1,
    name: "Adventure Safaris",
    // desc: "This package is perfect for those who want to experience the extreme outdoors Scotland has to offer",
    price: " POA",
    features: [
      { feature: "Hiking", available: true },
      { feature: "Trail Running", available: true },
      { feature: "Rafting", available: true },
      { feature: "Kayaking", available: true },
      { feature: "Cycling", available: true },
      { feature: "Mountain Biking", available: true },
      { feature: "Climbing", available: true },
      { feature: "Paddle Boarding", available: true },
      { feature: "Wild Swimming", available: true },
      { feature: "Falconry", available: true },
      { feature: "Surfing", available: true },
      { feature: "Kitesurfing", available: true },
      { feature: "Foil Boarding", available: true },
    ],
  },
  {
    id: 2,
    name: "Wildlife Safaris",
    // desc: "This is the perfect package for those who want to experience the harsh elements that will make you feel alive!",
    price: " POA",
    features: [
      { feature: "Nature Walks", available: true },
      { feature: "Marine Tours", available: true },
      { feature: "Loch Tours", available: true },
      { feature: "River Tours", available: true },
      { feature: "Birdwatching", available: true },
      { feature: "Photography", available: true },
      { feature: "Horse Riding", available: true },
      { feature: "Canoeing", available: true },
      { feature: "Sailing", available: false },
      { feature: "Foraging", available: false },
      { feature: "Self-Drive", available: false },
      { feature: "Sea Fishing", available: true },
      { feature: "Fly Fishing", available: true },
    ],
  },
  // {
  //   id: 3,
  //   name: "Wildlife Package",
  //   desc: "This package is perfect for those who want to see the beautiful wildlife Scotland has to offer",
  //   price: " POA",
  //   features: [
  //     { feature: "Nature Walks", available: true },
  //     { feature: "Ocean Tours", available: true },
  //     { feature: "BirdWatching", available: true },
  //     { feature: "Wilfdlife Photography", available: true },
  //     { feature: "Horse Riding", available: true },
  //     { feature: "Sea Kayaking", available: true },
  //     { feature: "Walking", available: true },
  //     { feature: "Northern Lights", available: false },
  //     { feature: "Skiing", available: false },
  //     { feature: "Climbing", available: false },
  //     { feature: "Cycling", available: false },
  //   ],
  // },
];

const Guide1 = require("./images/Guide1.png");
// const Guide2 = require('./images/Guide2.jpg')
// const Guide3 = require('./images/Guide3.jpg')
// const Guide4 = require('./images/Guide4.jpg')
// const Guide5 = require('./images/Guide5.jpg')
// const Guide6 = require('./images/Guide6.jpg')

export const trips = [
  {
    id: 1,
    image: Guide1,
    name: "Orlando Hill",
    job: "Adventure Guide",
    socials: [
      "https://www.instagram.com/_orlando_hill/",
      "https://twitter.com/",
      "https://www.facebook.com/orlando.hill.18",
      "https://www.linkedin.com/in/orlando-hill-5290431b3/",
    ],
  },
  {
    id: 2,
    // image: Guide2,
    name: "Daniel vinyo",
    job: "Speed Guide",
    socials: [
      "https://instagram.com/",
      "https://twitter.com/",
      "https://facebook.com/",
      "https://linkedin.com/",
    ],
  },
  // {
  //     id: 3,
  //     image: Guide3,
  //     name: 'Edem Quist',
  //     job: 'Flexibility Guide',
  //     socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
  // },
  // {
  //     id: 4,
  //     image: Guide4,
  //     name: 'Shatta Wale',
  //     job: 'Body Composition Guide',
  //     socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
  // },
  // {
  //     id: 5,
  //     image: Guide5,
  //     name: 'Diana Ayi',
  //     job: 'Circuit Guide',
  //     socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
  // },
  // {
  //     id: 6,
  //     image: Guide6,
  //     name: 'Wayne Carter',
  //     job: 'Physical Intelligence Guide',
  //     socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
  // }
];
