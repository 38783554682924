import { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom"; // Import useLocation
import { links } from "../data";
import { FaBars } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import "./Navbar.css";
import Logo from "../images/logomark-white.png";
import { React } from "react";

const Navbar = () => {
  const [isNavShowing, setIsNavShowing] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation(); // Get the current route

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === "/" && window.scrollY > 150) {
        // Only apply scroll logic if we're on the homepage
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    if (location.pathname === "/") {
      // Only add the scroll listener if we're on the homepage
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up the event listener
    };
  }, [location.pathname]); // Dependency on location.pathname

  return (
    <nav
      className={`${
        location.pathname === "/" ? (isScrolled ? "scrolled" : "homepage") : ""
      }`}
    >
      <div className="container nav__container">
        <Link to="/" className="logo" onClick={() => setIsNavShowing(false)}>
          <img src={Logo} alt="Logo" id="logo1" />
          <a className="logoText">SCOTLAND SAFARIS</a>
        </Link>
        <ul
          className={`nav__links ${isNavShowing ? "show__nav" : "hide__nav"}`}
        >
          {links.map(({ name, path }, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={path}
                  className={({ isActive }) =>
                    isActive
                      ? isScrolled
                        ? "active-nav active-nav-scrolled"
                        : "active-nav"
                      : ""
                  }
                  onClick={() => setIsNavShowing((prev) => !prev)}
                >
                  {" "}
                  {name}{" "}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <button
          className="nav__toggle-btn"
          onClick={() => setIsNavShowing((prev) => !prev)}
        >
          {isNavShowing ? <GrClose /> : <FaBars />}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
