import Header from "../../components/Header";
import HeaderImage from "../../images/Otter.JPG";
import { trips } from "../../data";
import { BsInstagram } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import Trip from "../../components/Trip";
import "./Trips.css";
import { React } from "react";

const Trips = () => {
  return (
    <>
      <Header title="Plan Your Trip" image={HeaderImage}>
        Our guides are the best in the business, they know the land like the
        back of their hand and will make sure you have the best experience
        possible.
      </Header>
      <section className="trips">
        <div className="container trips__container">
          {trips.map(({ id, image, name, job, socials }) => {
            return (
              <Trip
                key={id}
                image={image}
                name={name}
                job={job}
                socials={[
                  { icon: <BsInstagram />, link: socials[0] },
                  { icon: <FaFacebook />, link: socials[1] },
                  { icon: <FaLinkedin />, link: socials[2] },
                  { icon: <AiOutlineTwitter />, link: socials[3] },
                ]}
              />
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Trips;
