import Header from "../../components/Header";
import HeaderImage from "../../images/aboutus.jpeg";
import StoryImage from "../../images/Guide1.png";
import VisionImage from "../../images/SunsetSq.jpeg";
import MissionImage from "../../images/FoinavenSq.jpeg";
import { React } from "react";

import "./About.css";

const About = () => {
  return (
    <>
      <Header title="About Us" image={HeaderImage}>
        We’re specialists in tailor-made wildlife and adventure safaris. We
        provide unprecedented access to a multitude of individuals and
        organizations across every corner of Scotland. We offer unique
        experiences alongside luxury accommodation, guaranteeing you memories
        that will last a lifetime.
      </Header>

      <section className="about__story">
        <div className="container about__story-container">
          <div className="about__section-image">
            <img src={StoryImage} alt="Our Story img" />
          </div>
          <div className="about__section-content">
            <h1>WHO</h1>
            <p>
              Scotland Safaris is a Luxury tailor-made tour op- erator linking
              adventure providers across Scot- land. They allow you to handpick
              your dream holiday and aim to give people the opportunity to enjoy
              the best of Scotland through offering travel, adventure and
              safaris.
            </p>
            <p>
              {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis
              non tempora accusamus suscipit cupiditate saepe animi eveniet
              facere minus velit? */}
            </p>
          </div>
        </div>
      </section>

      <section className="about__vision">
        <div className="container about__vision-container">
          <div className="about__section-content">
            <h1>MISSION</h1>
            <p>
              Scotland Safaris aim to give everybody the opportunity to enjoy
              Scotland at its best. They want to do this in a way that develops
              people’s understanding and love for the wild, unlocking the
              benefits one can gain both physically and mentally from being
              outdoors & immersing onself in nature. They aim to improve the
              planet for all.
            </p>
            <p>
              {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis
              non tempora accusamus suscipit cupiditate saepe animi eveniet
              facere minus velit? */}
            </p>
          </div>
          <div className="about__section-image">
            <img src={VisionImage} alt="Our vision img" />
          </div>
        </div>
      </section>

      <section className="about__mission">
        <div className="container about__mission-container">
          <div className="about__section-image">
            <img src={MissionImage} alt="Our mission img" />
          </div>
          <div className="about__section-content">
            <h1>WHAT</h1>
            <p>
              Scotland Safaris aim to give everybody the opportunity to enjoy
              Scotland at its best. They want to do this in a way that develops
              people’s understanding and love for the wild, unlocking the
              benefits one can gain both physically and mentally from being
              outdoors & immersing onself in nature. They aim to improve the
              planet for all.
            </p>
            {/* <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis
              non tempora accusamus suscipit cupiditate saepe animi eveniet
              facere minus velit?
            </p> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
